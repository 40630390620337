import { uniqueId } from "lodash";
import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { setAlert } from "../../actions/alert";
import { Drive } from "../../actions/google";
import { getRoomParts } from "../../actions/mozaik/parts";
import Button from "../../components/Elements/Button";
import JobSelect from "../../components/Jobs/JobSelect";
import { load, createLinkedProductFunction, isNumber, getMaxLineBoreQuantity, isManuallyAddedHole } from "./OptimizerRules/OptimizerUtils";
import { removeExtraHolesAlongLineBore } from "./OptimizerRules/RemoveExtraHolesAlongLineBore";
import OptimizerVBEdgeFix from "./OptimizerVBEdgeFix";
import { roundDown, roundUp } from "../../utils/general";

/**
 *
 * Other Features:
 *  - Copy Parts from One Job File to current
 *      - as a new part
 *      - to replace existing part
 *  - Copy operations from a part in another job file to the current
 *  - Ability to specify cabinet number and part name
 */

const Optimizer = ({ jobFolder, jobParts, rooms, setAlert }) => {
  const [files, setFiles] = useState([]);
  const [complete, setComplete] = useState(false);
  const [report, setReport] = useState([]);
  const [operations, setOperations] = useState({
    vbEdgeFix: [],
  });

  console.log({ operations });

  const ref = useRef();

  async function fetchFiles(jobFolder) {
    if (jobFolder.id) {
      const files = (await Drive.getFilesByFolder(jobFolder.id)).filter(file => file.name.endsWith(".opt"));
      if (!files.length) setAlert("error", "No optimizer files found.");
      setFiles(files);
    }
  }

  useEffect(() => {
    fetchFiles(jobFolder);
  }, [jobFolder.id]);

  async function sanitizeFiles() {
    ref.current.textContent = "Running...";
    ref.current.setAttribute("disabled", true);
    const issues = [];
    for (const file of files) {
      await sanitizeOptimizerFile(file, issues);
    }
    setReport(issues);
    setComplete(true);
  }

  function getThroughDrillDepth(materialThickness) {
    return materialThickness;
  }

  async function sanitizeOptimizerFile(file, issues) {
    try {
      // @TODO: Need a way to check if the file has already been sanitized

      console.log("loading file...");

      console.log(file);

      let $ = await load(file);

      console.log("file loaded");

      const optimizeMaterial = $("OptimizeMaterial").attr("Name");
      console.log({ optimizeMaterial });

      const originalMaterial = $("OptimizeMaterial").attr("OriginalName") || optimizeMaterial;
      console.log({ originalMaterial });

      $("OptimizeMaterial").attr("OriginalName", originalMaterial);

      const materialThickness = $("OptimizeMaterial").attr("Thickness");
      console.log({ optimizeMaterial, materialThickness });

      const roundedMaterialThickness = Math.round(materialThickness);
      console.log({ roundedMaterialThickness });

      const thickness = roundedMaterialThickness === 19 ? "3/4" : roundedMaterialThickness === 16 ? "5/8" : roundedMaterialThickness === 25 ? "1 inch" : null;
      console.log({ thickness });

      const throughDrillDepth = getThroughDrillDepth(materialThickness);

      console.log({ optimizeMaterial, roundedMaterialThickness, thickness, throughDrillDepth });

      // Remove special characters (keep slashes) from optimizeMaterial. remove double spaces
      const sanitizedMaterial = optimizeMaterial
        .replace(/&quot;/g, " in")
        .replace(/"/g, " in")
        .replace(/[^\w\s/]/gi, "")
        .replace(/\s+/g, " ");

      $("OptimizeMaterial").attr("Name", sanitizedMaterial);
      console.log({ sanitizedMaterial });

      /**
       * Do we need to fix the VBs along the left edge? If so, run the OptimizerVBEdgeFix function
       * @TODO: What if the starting rotation is not 0? does this affect the X / Y / Width / Length values?
       * @TODO: Need to warn if flipping the sheet still won't fix it (i.e. are there also holes on the other side that are > SheetLength - Threshold away from right edge?)
       */

      if (operations.vbEdgeFix.includes(file.name)) {
        console.warn("RUNNING VB EDGE FIX...");

        const threshold = 50; // mm
        const optimizerSheets = $("OptimizeSheet").toArray();

        for (const sheet of optimizerSheets) {
          const sheetWidth = Number($(sheet).attr("Width"));
          const sheetLength = Number($(sheet).attr("Length"));
          const sheetNumber = $(sheet).attr("PatternNumber");
          const sheetParts = $(sheet).find("OptimizePartLocation").toArray();

          console.warn("IS FLIP SHEET? ", isFlipSheet($, sheet));

          const issueSheet = isIssueSheet($, sheet, threshold);

          if (issueSheet) {
            console.log(`Sheet ${sheetNumber} has issues: `, sheetNumber);

            // const flipSheet = isFlipSheet($, sheet);
            // if (flipSheet) {
            //   console.log(`Sheet ${sheetNumber} has issues but also flip operations: `, sheetNumber);
            //   issues.push({
            //     type: "vb-edge-fix",
            //     msg: `VB EDGE FIX: FLIPSHEET! Pattern #${sheetNumber} has VB issue but is a flip sheet, must be corrected manually!`,
            //   });
            //   continue;
            // }

            // this means the sheet has parts with holes that are not 5mm in diameter and are too close to the left edge
            // therefore we need to set the rotation for each OptimizePartLocation on the sheet to 180 degrees and recalculate the X value for each part

            issues.push({
              type: "vb-edge-fix",
              msg: `VB EDGE FIX: Pattern #${sheetNumber}. Adjusted the sheet`,
            });

            sheetParts.forEach(partLocation => {
              const partId = $(partLocation).attr("PartID");
              const part = $(`OptimizePart[PartID="${partId}"]`);
              const partWidth = Number($(part).attr("Width"));
              const partLength = Number($(part).attr("Length"));
              const rotation = Number($(partLocation).attr("Rotation"));

              let newRotation = rotation + 180;
              if (newRotation >= 360) newRotation -= 360;

              $(partLocation).attr("Rotation", String(newRotation));

              if (rotation == 0 || rotation == 180) {
                const currentX = Number($(partLocation).attr("X"));
                const originalX = $(partLocation).attr("OriginalXForVBEdgeFix");
                const newX = recalculateX(currentX, sheetLength, partLength);
                $(partLocation).attr("X", String(newX));
                $(partLocation).attr("OriginalXForVBEdgeFix", originalX || String(currentX));
              } else {
                const currentX = Number($(partLocation).attr("X"));
                const originalX = $(partLocation).attr("OriginalXForVBEdgeFix");
                const newX = recalculateX(currentX, sheetLength, partWidth);
                $(partLocation).attr("X", String(newX));
                $(partLocation).attr("OriginalXForVBEdgeFix", originalX || String(currentX));
              }
            });
          }
        }

        const output = "4\n" + $.xml().replace(/  /g, "");
        console.log((output.match(/  /g) || []).length);
        const base64 = btoa(output);
        const res = await Drive.updateFile(file.id, "audio/mpeg", base64, file.parents[0]);
        console.log(res);
        console.log("finished edge fix. exiting...");
        return;
      }

      console.log("continuing...");

      const getLinkedProduct = createLinkedProductFunction(rooms);

      // Top Shelf Holes for 1 inch material

      function addTopShelfHoles(part, issues) {
        const cabNumber = isNumber($(part).attr("AssyNo")) ? "R1C" + $(part).attr("AssyNo") : $(part).attr("AssyNo");
        const name = $(part).attr("Name");
        const length = $(part).attr("Length");

        const operationHoles = $(part)
          .find("OperationHole")
          .toArray()
          .map(hole => Number($(hole).attr("Y")));

        const Ymin = Math.min(...operationHoles);
        const Ymax = Math.max(...operationHoles);

        const X = Number(length) - 16;
        const depth = 13; // @TODO:

        $(part)
          .find("Operations")
          .append(
            `<OperationHole IsScriptAddition="True" Diameter="5" Diameter_Eq="" ID="1" X="${X}" Y="${Ymin}" Depth="${depth}" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" />`
          );
        issues.push({
          type: "gable",
          msg: `ADDED TOP SHELF HOLE: Adding a hole at position X: ${X}, Y: ${Ymin} for ${name} (${cabNumber})`,
        });
        $(part)
          .find("Operations")
          .append(
            `<OperationHole IsScriptAddition="True" Diameter="5" Diameter_Eq="" ID="1" X="${X}" Y="${Ymax}" Depth="${depth}" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" />`
          );
        issues.push({
          type: "gable",
          msg: `ADDED TOP SHELF HOLE: Adding a hole at position X: ${X}, Y: ${Ymax} for ${name} (${cabNumber})`,
        });
      }

      function addBottomShelfHoles(part, issues) {
        const cabNumber = isNumber($(part).attr("AssyNo")) ? "R1C" + $(part).attr("AssyNo") : $(part).attr("AssyNo");
        const name = $(part).attr("Name");
        const operationHoles = $(part)
          .find("OperationHole")
          .toArray()
          .map(hole => Number($(hole).attr("Y")));

        const Ymin = Math.min(...operationHoles);
        const Ymax = Math.max(...operationHoles);

        const X = 10;
        const depth = 13; // @TODO:

        $(part)
          .find("Operations")
          .append(
            `<OperationHole IsScriptAddition="True" Diameter="5" Diameter_Eq="" ID="1" X="${X}" Y="${Ymin}" Depth="${depth}" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" />`
          );
        issues.push({
          type: "gable",
          msg: `ADDED TOP SHELF HOLE: Adding a hole at position X: ${X}, Y: ${Ymin} for ${name} (${cabNumber})`,
        });
        $(part)
          .find("Operations")
          .append(
            `<OperationHole IsScriptAddition="True" Diameter="5" Diameter_Eq="" ID="1" X="${X}" Y="${Ymax}" Depth="${depth}" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" />`
          );
        issues.push({
          type: "gable",
          msg: `ADDED TOP SHELF HOLE: Adding a hole at position X: ${X}, Y: ${Ymax} for ${name} (${cabNumber})`,
        });
      }

      function getIsBackedCabinet(linkedProduct) {
        console.log({ linkedProduct });
        const isBackedCabinet = $(linkedProduct).find('CabProdPart[Name="UBack"]').length > 0 || $(linkedProduct).find('CabProdPart[ReportName="Backing"]').length > 0;
        console.log({ isBackedCabinet });
        return isBackedCabinet;
      }

      function getIsSink(linkedProduct) {
        console.log({ linkedProduct });
        // const isSink = $(linkedProduct).find('CabProdPart[Name="Sink"]').length > 0;
        // console.log({ isSink });
        // return isSink;
      }

      function getCabNumber(assemblyNumber) {
        return isNumber(assemblyNumber) ? `R1C${assemblyNumber}` : assemblyNumber[0] === "N" ? `R1${assemblyNumber}` : assemblyNumber;
      }

      // top shelf holes for gables are 5mm diameter operation holes that are closest to the top of the part (X value is closest to the part's length) and has at least 2 holes at the same X value

      function getTopShelfHoles(part) {
        // Get all OperationHole elements
        const operationHoles = $(part).find(`OperationHole[Diameter="5"]`);

        // Group OperationHoles by X attribute
        const groupedByX = operationHoles.toArray().reduce((acc, hole) => {
          const x = parseFloat($(hole).attr("X"));
          if (!acc[x]) {
            acc[x] = [];
          }
          acc[x].push(hole);
          return acc;
        }, {});

        // Filter groups with 2 or more holes
        const groupsWithTwoOrMore = Object.entries(groupedByX).filter(([_, group]) => group.length >= 2);

        // Find the group with the largest X value
        const largestXGroup = groupsWithTwoOrMore.reduce((max, current) => {
          return parseFloat(current[0]) > parseFloat(max[0]) ? current : max;
        }, groupsWithTwoOrMore[0]);

        // Return the group with the largest X value, or null if no such group exists
        return largestXGroup ? largestXGroup[1] : null;
      }

      // bottom shelf holes for gables are 5mm diameter operation holes that are closest to the bottom of the part (X value is closest to 0) and has at least 2 holes at the same X value

      function getBottomShelfHoles(part) {
        const operationHoles = $(part).find(`OperationHole[Diameter="5"]`);

        const groupedByX = operationHoles.toArray().reduce((acc, hole) => {
          const x = parseFloat($(hole).attr("X"));
          if (!acc[x]) {
            acc[x] = [];
          }
          acc[x].push(hole);

          return acc;
        }, {});

        const groupsWithTwoOrMore = Object.entries(groupedByX).filter(([_, group]) => group.length >= 2);

        const smallestXGroup = groupsWithTwoOrMore.reduce((min, current) => {
          return parseFloat(current[0]) < parseFloat(min[0]) ? current : min;
        }, groupsWithTwoOrMore[0]);

        return smallestXGroup ? smallestXGroup[1] : null;
      }

      $("OptimizePart")
        .toArray()
        .forEach(part => {
          try {
            const assemblyNumber = $(part).attr("AssyNo");
            const cabNumber = getCabNumber(assemblyNumber);
            const name = $(part).attr("Name").trim();

            console.log("###########################################");
            console.log({ cabNumber, name });

            const partId = $(part).attr("PartID");
            const width = $(part).attr("Width");
            const length = $(part).attr("Length");
            const maxLineBoreQuantity = getMaxLineBoreQuantity($, part);
            const isWallCab = cabNumber.startsWith("N");
            const maxLength = Math.max(width, length);
            const minLength = Math.min(width, length);
            const hasLineBore = maxLineBoreQuantity > 0;
            const linkedProduct = getLinkedProduct(cabNumber);
            const sourceLib = linkedProduct ? $(linkedProduct).attr("SourceLib") : null;

            console.log({ sourceLib, linkedProduct });
            const isBackedCabinet = getIsBackedCabinet(linkedProduct);
            // const isSink =

            // delete parts named "Face Kick"
            if (name.includes("Face Kick")) {
              $(part).remove();
              issues.push({
                type: "remove-face-kick",
                msg: `REMOVED FACE KICK: ${cabNumber}`,
              });
            }

            // delete parts named "Sleeper"
            if (name.includes("Sleeper")) {
              $(part).remove();
            }

            // modify backed cabinet parts

            if (isBackedCabinet) {
              // Remove VB holes (10mm and 20mm diameter) from top and bottom shelves
              // Also, reduce Top and Bottom shelf depth by 1mm when there are no VB operations (holes that are either 10mm or 20mm in diameter)
              // @REASON: With cabinets, Top and Bottom shelves end up sticking out too far and cause the backer to stick out ~1mm
              if (name === "Top") {
                $(part)
                  .find("OperationHole")
                  .toArray()
                  .forEach(hole => {
                    const diameter = $(hole).attr("Diameter");
                    if (diameter == "10" || diameter == "20") {
                      $(hole).remove();
                    }
                  });
                reduceTopBottomShelfDepth($, part, cabNumber, issues);
              }

              // Remove VB holes (10mm and 20mm diameter) from bottom shelves ONLY when the Z value is 0 (when the bottom shelf is flush with the bottom of the cabinet)
              if (name === "Bottom") {
                // find the bottom shelf in the linked product
                const bottomShelf = $(linkedProduct).find('CabProdPart[Name="Bottom"]');
                console.log({ bottomShelf });

                // get the Z value of the bottom shelf
                const bottomShelfZ = Number($(bottomShelf).attr("Z"));
                console.log({ bottomShelfZ });

                if (bottomShelfZ === 0) {
                  $(part)
                    .find("OperationHole")
                    .toArray()
                    .forEach(hole => {
                      const diameter = $(hole).attr("Diameter");
                      if (diameter == "10" || diameter == "20") {
                        $(hole).remove();
                      }
                    });
                  reduceTopBottomShelfDepth($, part, cabNumber, issues);
                }
              }

              if (name === "Fixed Shelf") {
                reduceTopBottomShelfDepth($, part, cabNumber, issues);
              }

              // if it's a gable, make the top and bottom shelf holes through-drilled
              if (name.includes("Gable")) {
                const topShelfHoles = getTopShelfHoles(part);
                const bottomShelfHoles = getBottomShelfHoles(part);

                if (topShelfHoles) {
                  topShelfHoles.forEach(hole => {
                    $(hole).attr("Depth", throughDrillDepth);
                    $(hole).attr("Diameter", "3");
                  });
                  issues.push({
                    type: "gable",
                    msg: `TOP SHELF THROUGH DRILL: ${topShelfHoles.length} top shelf holes have been through-drilled for ${name} (${cabNumber})`,
                  });
                }

                if (bottomShelfHoles) {
                  bottomShelfHoles.forEach(hole => {
                    const X = Number($(hole).attr("X"));
                    // only through-drill holes that are less than or equal to 25mm from the bottom of the gable (which means the bottom shelf is flush with the bottom of the cabinet)
                    if (X <= 25) {
                      $(hole).attr("Depth", throughDrillDepth);
                      $(hole).attr("Diameter", "3");
                    }
                  });
                  issues.push({
                    type: "gable",
                    msg: `BOTTOM SHELF THROUGH DRILL: ${bottomShelfHoles.length} bottom shelf holes have been through-drilled for ${name} (${cabNumber})`,
                  });
                }
              }

              if (name.includes("Gable")) {
                // Right Gable: Front is {0,0}
                if (name.includes("(R)")) {
                  const lineBores = $(part).find("OperationLineBore").toArray();
                  const partWidth = Number($(part).attr("Width"));
                  const targetY = Math.floor(partWidth - 37);
                  const updatedY = partWidth - 56;
                  lineBores.forEach(lineBore => {
                    const Y = Math.floor(Number($(lineBore).attr("Y")));
                    if (Y === targetY) {
                      $(lineBore).attr("Y", String(updatedY));
                      issues.push({
                        type: "gable",
                        msg: `MOVED BACKED CAB LINE BORE: From position Y: ${Y} --- to --- Y: ${updatedY} for ${name} (${cabNumber})`,
                      });
                    }
                  });
                }

                // Left Gable: Back is {0,0}
                if (name.includes("(L)")) {
                  const lineBores = $(part).find("OperationLineBore").toArray();
                  const targetY = 37;
                  const updatedY = 56;
                  lineBores.forEach(lineBore => {
                    const Y = Math.floor(Number($(lineBore).attr("Y")));
                    if (Y === targetY) {
                      $(lineBore).attr("Y", String(updatedY));
                      issues.push({
                        type: "gable",
                        msg: `MOVED BACKED CAB LINE BORE: From position Y: ${Y} --- to --- Y: ${updatedY} for ${name} (${cabNumber})`,
                      });
                    }
                  });
                }
              }

              // add 3mm operation holes 9.5mm from the back of the gable along the X axis of the gable (Y = 0 is the front of the gable and Y = Width is the back of the gable)
              // @REASON: To pre-drill holes in the gable to screw into the backing
              if (name.includes("Gable")) {
                const isRightGable = name.includes("(R)");
                const depth = throughDrillDepth;
                const width = $(part).attr("Width");
                const Y = isRightGable ? Number(width) - 9.5 : 9.5;
                const length = $(part).attr("Length");

                // add hole 76.2mm down from the top of the gable (X = length - 76.2)
                $(part)
                  .find("Operations")
                  .append(
                    `<OperationHole IsScriptAddition="True" Diameter="3" Diameter_Eq="" ID="1" X="${
                      length - 76.2
                    }" Y="${Y}" Depth="${depth}" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" />`
                  );

                const middleLength = Number(length) - 76.2 - 76.2;
                const numberOfSections = Math.floor(middleLength / 406.4); // every 16" pre-drill a hole
                const holeSeparation = numberOfSections > 1 ? middleLength / numberOfSections : middleLength / 2;

                for (let i = 76.2; i < length; i += holeSeparation) {
                  $(part)
                    .find("Operations")
                    .append(
                      `<OperationHole IsScriptAddition="True" Diameter="3" Diameter_Eq="" ID="1" X="${i}" Y="${Y}" Depth="${depth}" Hide="False" X_Eq="" Y_Eq="" Depth_Eq="" Hide_Eq="" IsUserOp="True" IdTag="9999" Noneditable="False" Anchor="" FlipSideOp="False" />`
                    );
                }

                issues.push({
                  type: "gable",
                  msg: `ADDED GABLE PRE-DRILL HOLES: pre-drill holes have been added to the gable for ${name} (${cabNumber})`,
                });
              }
            }

            // run operations

            if (operations.romina && name.includes("Gable")) {
              console.log("running romina operations...");
              $(part)
                .find("OperationHole")
                .toArray()
                .forEach(hole => {
                  const X = $(hole).attr("X");

                  if (X == "316.9875") {
                    $(hole).attr("X", "329.50");
                    issues.push({
                      type: "gable",
                      msg: `MOVED HOLE: Moved a hole from position X: ${X} --- to --- X: 329.50 for ${name} (${cabNumber})`,
                    });
                  }
                  if (X == "541.1875") {
                    $(hole).attr("X", "553.50");
                    issues.push({
                      type: "gable",
                      msg: `MOVED HOLE: Moved a hole from position X: ${X} --- to --- X: 553.50 for ${name} (${cabNumber})`,
                    });
                  }
                  if (X == "765.3875") {
                    $(hole).attr("X", "777.50");
                    issues.push({
                      type: "gable",
                      msg: `MOVED HOLE: Moved a hole from position X: ${X} --- to --- X: 777.50 for ${name} (${cabNumber})`,
                    });
                  }
                });
            }

            if (operations.biggerHoles && name.includes("Gable")) {
              console.log("running bigger holes operations...");
              $(part)
                .find("OperationHole")
                .toArray()
                .forEach(hole => {
                  const diameter = $(hole).attr("Diameter");
                  if (diameter == "7") {
                    $(hole).attr("Original_Diameter", "7");
                    $(hole).attr("Diameter", "7.5");
                    issues.push({
                      type: "gable",
                      msg: `INCREASED HOLE DIAMETER: Increased the diameter of a hole from 7mm to 7.5mm for ${name} (${cabNumber})`,
                    });
                  }
                });

              $(part)
                .find("OperationLineBore")
                .toArray()
                .forEach(lineBore => {
                  const diameter = $(lineBore).attr("Diameter");
                  if (diameter == "7") {
                    $(lineBore).attr("Original_Diameter", "7");
                    $(lineBore).attr("Diameter", "7.5");
                    issues.push({
                      type: "gable",
                      msg: `INCREASED LINE BORE DIAMETER: Increased the diameter of a line bore from 7mm to 7.5mm for ${name} (${cabNumber})`,
                    });
                  }
                });
            }

            // move line bore for 5/8" White
            if (thickness === "5/8" && name.includes("Gable")) {
              const amountToIncrease = 2.6; // shift line bore up by 2.6mm
              const screwInDowelDepth = 10;
              const boardThickness = 16;

              const length = $(part).attr("Length");
              const lineBores = $(part).find("OperationLineBore").toArray();
              lineBores.forEach(lineBore => {
                const origin = $(lineBore).attr("X");
                const Y = $(lineBore).attr("Y");
                const depth = $(lineBore).attr("Depth");
                const isDrilledThrough = depth >= boardThickness;

                const X = Number(origin) + amountToIncrease;
                console.log({ origin, Y, X });
                $(lineBore).attr("X", String(X));

                issues.push({
                  type: "gable",
                  msg: `MOVED LINE BORE DOWN: Moved a line bore from position X: ${origin}, Y: ${Y} --- to --- X: ${X}, Y: ${Y} for ${name} (${cabNumber})`,
                });

                // if it is not drilled through then we need to reduce the depth of the screw in dowel hole from 13mm (for 3/4" dowels) to 10mm

                if (!isDrilledThrough) {
                  $(lineBore).attr("Depth", String(screwInDowelDepth));
                  issues.push({
                    type: "gable",
                    msg: `REDUCED LINE BORE DEPTH: Reduced the depth of a line bore from ${depth}mm to ${screwInDowelDepth}mm for ${name} (${cabNumber})`,
                  });
                }
              });
            }

            if (thickness === "5/8" && ["Fixed Shelf", "Top", "Bottom"].includes(name)) {
              /**
             * VBs
                  We used 3/4" VBs because the 5/8" VBs caused misalignment
                  20mm diameter holes (big holes)
                  Depth = 13.3 mm
                  10mm diameter holes (small holes)
                  Depth = 11 mm
             */

              $(part)
                .find("OperationHole")
                .toArray()
                .forEach(hole => {
                  const diameter = Number($(hole).attr("Diameter"));
                  const depth = $(hole).attr("Depth");
                  if (diameter == 20) {
                    $(hole).attr("Depth", "13.3");
                    issues.push({
                      type: "gable",
                      msg: `REDUCED BIG VB HOLE DEPTH: Reduced the depth of a hole from ${depth}mm to 13.3mm for ${name} (${cabNumber})`,
                    });
                  } else if (diameter == 10) {
                    $(hole).attr("Depth", "11");
                    issues.push({
                      type: "gable",
                      msg: `REDUCED SMALL VB HOLE DEPTH: Reduced the depth of a hole from ${depth}mm to 11mm for ${name} (${cabNumber})`,
                    });
                  }
                });
            }

            // MOVE TOP SHELF HOLES DOWN FOR 1 INCH MATERIAL
            // @REASON: The 1 inch material shelves are thicker and therefore the holes need to be lower
            // @INDEX: TOP SHELF HOLES

            // @FIX: This function will not run if the gable is 3/4 even IF the SHELF THICKNESS IS 1 INCH

            console.log({ thickness, name });

            if (thickness === "1 inch" && name.includes("Gable")) {
              // get top shelf holes
              const length = $(part).attr("Length");
              const threshold = Number(length) - 12.7 - 5;
              console.log("threshold", threshold);

              const X = Number(length) - 16;

              const topShelfHoles = $(part)
                .find("OperationHole")
                .toArray()
                .filter(hole => Number($(hole).attr("X") > threshold));

              console.log({ topShelfHoles: topShelfHoles.length });

              console.warn("CAB: ", cabNumber);

              console.warn("TOP SHELF HOLES: ", topShelfHoles);

              topShelfHoles.forEach(hole => {
                const origin = $(hole).attr("X");
                const Y = roundUp(Number($(hole).attr("Y")), 2);
                const FlipSideOp = $(hole).attr("FlipSideOp");
                $(hole).attr("X", X);

                console.warn({ origin, X, Y, FlipSideOp });

                // adjust line bore quantity if it overlaps with the new top shelf hole

                const lineBores = $(part)
                  .find(`OperationLineBore[FlipSideOp="${FlipSideOp}"]`)
                  .toArray()
                  .filter(lineBore => roundUp(Number($(lineBore).attr("Y")), 2) === Y);
                console.warn("LINE BORES: ", lineBores);

                lineBores.forEach(lineBore => {
                  const start = Number($(lineBore).attr("X"));
                  const qty = Number($(lineBore).attr("Quan"));
                  const length = qty * 32;
                  const end = start + length - 32;

                  console.warn({ start, end, qty, length });

                  if (start < X && end > X) {
                    console.warn("ADJUSTING LINE BORE QUANTITY");
                    $(lineBore).attr("Quan", String(qty - 1));
                  }
                });

                issues.push({
                  type: "gable",
                  msg: `MOVED TOP SHELF HOLE: Moved a hole from position X: ${origin}, Y: ${Y} --- to --- X: ${X}, Y: ${Y} for ${name} (${cabNumber})`,
                });
              });
            }

            // Increase size of Face Kick

            if (name === "Face Kick") {
              const length = $(part).attr("OriginalLength") || $(part).attr("Length");
              $(part).attr("OriginalLength", length);
              // $(part).attr("Length", Number(length) * 2);
            }

            // Increase width of Front Kick by 1/8"

            // if (name === "Front Kick") {
            //   const width = $(part).attr("OriginalWidth") || $(part).attr("Width");
            //   $(part).attr("OriginalWidth", width);
            //   $(part).attr("Width", Number(width) + 3.175);
            //   issues.push({
            //     type: "frontKick",
            //     msg: `INCREASED FRONT KICK WIDTH: Increased the width of a front kick from ${width}mm to ${Number(width) + 3.175}mm for ${name} (${cabNumber})`,
            //   });
            // }

            // Remove VB Operations (10mm and 20mm diameter OperationHoles) when name is "Partition"

            if (name === "Partition") {
              let isFlipSideOp;

              const vbDiameters = ["10", "20"];
              let count = 0;
              vbDiameters.forEach(diameter => {
                const operationHoles = $(part).find(`OperationHole[Diameter="${diameter}"]`).toArray();
                operationHoles.forEach(hole => {
                  isFlipSideOp = $(hole).attr("FlipSideOp");
                  $(hole).remove();
                  count++;
                });
              });

              issues.push({
                type: "remove-partition-vbs",
                msg: `REMOVED ${count} VB holes for ${name} (${cabNumber})`,
              });

              // change all 5mm holes on the same flip side to through drills

              const operationHoles = $(part).find(`OperationHole[Diameter="5"]`).toArray();
              operationHoles.forEach(hole => {
                if ($(hole).attr("FlipSideOp") === isFlipSideOp) {
                  $(hole).attr("Depth", throughDrillDepth);
                  issues.push({
                    type: "remove-partition-operation-hole",
                    msg: `CHANGED 5mm hole to through-drill (${throughDrillDepth}mm) for ${name} (${cabNumber}) at X: ${$(hole).attr("X")} Y: ${$(hole).attr("Y")}`,
                  });
                } else {
                  issues.push({
                    type: "remove-partition-operation-hole",
                    msg: `REMOVED 5mm hole for ${name} (${cabNumber}) at X: ${$(hole).attr("X")} Y: ${$(hole).attr("Y")}`,
                  });
                  $(hole).remove();
                }
              });
            }

            // Remove overlapping holes, with a preference to delete through-drills (maybe? confirm this)

            removeOverlappingHoles($, part, cabNumber, issues, "True");
            removeOverlappingHoles($, part, cabNumber, issues, "False");

            // Remove extra holes that occur along a line bore

            if (!isWallCab) {
              removeExtraHolesAlongLineBore($, part, "True", issues); // FlipSideOp = 'True'
              removeExtraHolesAlongLineBore($, part, "False", issues); // FlipSideOp = 'False'
            }

            // Remove holes that are outside of the part

            removeHolesOutsidePartShape($, part, cabNumber, issues);

            // Remove holes in Optimizer part that aren't in mozaik part

            // if (linkedProduct) removeHolesNotFoundinMozaik($, part, linkedProduct, cabNumber, issues);

            // Increase End Panel Height

            // if (name === "Flat end panel") increaseEndPanelHeight($, part, cabNumber, issues);

            // @TODO: Remove regular kicks from units with face kicks

            // @TODO: If drawer faces are SLAB throw warning to confirm drawer faces are accurate

            // @TODO: Check for missing bottom shelf holes if unit has line bore. You'll want to run the optimizer to look for this. If they are missing you either need to increase the gable height by 1/16" or add fastener "RaFix 20-32mm Closets".

            // Switch Width and Length for Finished Panels and Tray Bottoms

            if (name === "Finished Panel" || name === "Tray Bottom") switchWidthAndLength($, part, cabNumber, issues);

            // Check for first hole on wall gable

            if (name.startsWith("Wall Gable") && hasLineBore) {
            }

            // Remove unnessary line bore on backed wall cabinet

            if (name.startsWith("Gable") && hasLineBore && cabNumber.includes("N")) {
              // look for adjustable shelves with this same cabinet number
              // if there aren't any, remove line bore
            }

            // check flip side line bore holes line up with other side. If the difference is less than 5mm then adjust the line bore with Qty < 10 holes (basically want the holes on each side to line up)

            // Baseboard confirmation

            if (name.startsWith("Gable")) {
              const shapePoints = $(part).find('ShapePoint[X="0"]').toArray();
              const zeroPoints = shapePoints.filter(point => $(point).attr("Y") == "0");
              if (zeroPoints.length == 5 || shapePoints == 4) {
                issues.push({
                  type: "baseboard",
                  msg: `BASEBOARD: Gable ${cabNumber} does not have a baseboard notch. Please confirm this is correct.`,
                });
              }
            }

            // Fix slide holes to a 32mm system

            fixSlideHolesTo32mmSystem($, part, cabNumber, issues, false); // isFlip = false
            fixSlideHolesTo32mmSystem($, part, cabNumber, issues, true); // isFlip = true

            // // Enid

            // if (optimizeMaterial == "1 inch White" && cabNumber.startsWith("R1") && name.includes("Gable") && Number(length) > 2032) {
            //   // add top shelf holes for 1 inch material
            //   addTopShelfHoles(part, issues);
            //   addBottomShelfHoles(part, issues);
            // }
          } catch (err) {
            console.log(err);
            throw new Error(err);
          }
        });

      // @REMOVE ME

      // return;

      const output = "4\n" + $.xml().replace(/  /g, "");
      console.log((output.match(/  /g) || []).length);
      const base64 = btoa(output);
      const res = await Drive.updateFile(file.id, "audio/mpeg", base64, file.parents[0]);
      console.log(res);
    } catch (err) {
      console.log(err);
      ref.current.textContent = "Error: " + err.message;
    }
  }

  function recalculateX(currentX, sheetLength, partLength) {
    return sheetLength - currentX - partLength;
  }

  function isIssueSheet($, sheet, threshold) {
    const sheetParts = $(sheet).find("OptimizePartLocation").toArray();
    const leftEdgeParts = sheetParts.filter(part => Number($(part).attr("X")) < threshold);
    const hasIssueHoles = leftEdgeParts.some(partLocation => {
      const partId = $(partLocation).attr("PartID");
      const part = $(`OptimizePart[PartID="${partId}"]`);
      const issueHoles = $(part)
        .find("OperationHole")
        .toArray()
        .filter(hole => Number($(hole).attr("X")) < threshold && $(hole).attr("Diameter") !== "5");
      return issueHoles.length > 0;
    });
    return hasIssueHoles;
  }

  function isFlipSheet($, sheet) {
    const sheetParts = $(sheet).find("OptimizePartLocation").toArray();
    const partWithFlipOp = sheetParts.find(partLocation => {
      const partId = $(partLocation).attr("PartID");
      const part = $(`OptimizePart[PartID="${partId}"]`);
      const operationHoleFlipOp = $(part).find('OperationHole[FlipSideOp="True"]').length > 0;
      const operationLineBoreFlipOp = $(part).find('OperationLineBore[FlipSideOp="True"]').length > 0;
      return operationHoleFlipOp || operationLineBoreFlipOp;
    });
    if (partWithFlipOp) return true;
  }

  function increaseEndPanelHeight($, part, cabNumber, issues) {
    if ($(part).attr("OriginalLength")) return; // only increase the height if it hasn't already been increased
    const length = $(part).attr("OriginalLength") || $(part).attr("Length");
    $(part).attr("OriginalLength", length);
    $(part).attr("Length", String(Number(length) + 12.7));
    issues.push({
      type: "end-panel-height-increase",
      msg: `Increasing the End Panel height for: ${cabNumber} from "${Number(length).toFixed(2)}" to "${(Number(length) + 12.7).toFixed(2)}"`,
    });
  }

  // Reduce Top and Bottom shelf depth by 1mm when there are no VB operations (holes that are either 10mm or 20mm in diameter)
  // @REASON: With cabinets, Top and Bottom shelves end up sticking out too far and cause the backer to stick out ~1mm
  // Note the "Width" attribute is actually the depth for shelves

  function reduceTopBottomShelfDepth($, part, cabNumber, issues) {
    // Current not reducing
    return;

    // const vbHoles = [...$(part).find('OperationHole[Diameter="10"]').toArray(), ...$(part).find('OperationHole[Diameter="20"]').toArray()];
    // if (vbHoles.length) return;
    const depth = $(part).attr("OriginalWidth") || $(part).attr("Width");
    $(part).attr("OriginalWidth", depth);

    const newDepth = String(Number(depth) - 1);
    $(part).attr("Width", newDepth);
    // take off the 1mm from the back of the shelf
    $(part)
      .find(`ShapePoint[Y="${depth}"]`)
      .toArray()
      .forEach(point => {
        $(point).attr("Y", newDepth);
      });

    issues.push({
      type: "reduce-top-bottom-shelf-depth",
      msg: `Reducing the depth of the ${$(part).attr("Name")} for: ${cabNumber} from "${$(part).attr("OriginalWidth")}" to "${$(part).attr("Width")}"`,
    });
  }

  // Fix slide holes on Gables to a 32mm system
  // Create an array of arrays of OperationHoles that are 5mm in diameter and have 4 or more holes at the same X position, each array should be a group of holes at the same X position

  function fixSlideHolesTo32mmSystem($, part, cabNumber, issues, isFlip = false) {
    // console.warn("!!!!!!!!!!!! FIXING SLIDE HOLES !!!!!!!!!!!!!!!");

    const holes = $(part)
      .find(`OperationHole[Diameter="5"][FlipSideOp="${isFlip ? "True" : "False"}"]`)
      .toArray();
    if (holes.length === 0) return;

    // console.warn("HOLE LENGTH: ", holes.length);

    const groups = [];

    holes.forEach(hole => {
      const x = Number($(hole).attr("X"));
      const group = groups.find(group => group[0] && Number($(group[0]).attr("X")) === x);
      if (group) {
        group.push(hole);
      } else {
        groups.push([hole]);
      }
    });

    // console.warn("GROUPS: ", groups);

    const slideHoles = groups.filter(group => group.length >= 4);
    if (slideHoles.length === 0) return;

    // console.warn("SLIDE HOLES: ", slideHoles);

    if (slideHoles.length === 1) {
      console.warn("ONLY ONE SLIDE HOLE ROW. NO NEED TO FIX");
      return;
    }

    issues.push({
      type: "fix-slide-holes-to-32mm-system",
      msg: `Fixing ${slideHoles.length - 1} slide rows to a 32mm system for: ${cabNumber}`,
    });

    // We don't want to change the first row of holes because they are already aligned to the 32mm system
    // We use the first row as a starting point to calculate the rest of the slide positions

    const firstRow = slideHoles.shift(); // removes the first row
    // console.warn("FIRST ROW: ", firstRow);

    const firstRowX = Number($(firstRow[0]).attr("X"));
    // console.warn("FIRST ROW X: ", firstRowX);

    // use the firstRowX as a starting point and create an array of X positions that are 32mm apart but make sure the last position is less than the part length
    const slidePositions = [];

    const partLength = Number($(part).attr("Length"));

    let currentX = firstRowX;

    while (currentX < partLength) {
      slidePositions.push(currentX);
      currentX += 32;
    }

    // console.warn({ slidePositions });

    // now we want to take each row of slideHoles and move them to the nearest position in the slidePositions array that is greater than the hole's current X position (i.e. move the holes up to the nearest 32mm mark)
    slideHoles.forEach(row => {
      row.forEach(hole => {
        const holeX = Number($(hole).attr("X"));
        const newX = slidePositions.find(position => position >= holeX);
        $(hole).attr("X", String(newX));
      });
    });
  }

  // Flip the width and length of the part for Finished Panels and Tray Bottoms

  function switchWidthAndLength($, part, cabNumber, issues) {
    if ($(part).attr("DimensionsFlipped") == "True") return; // already flipped
    console.log("Flipping the width and length of the part for: ", cabNumber);
    const width = $(part).attr("Width");
    const length = $(part).attr("Length");
    $(part).attr("DimensionsFlipped", "True");
    $(part).attr("Width", length);
    $(part).attr("Length", width);
    issues.push({
      type: "switch-width-and-length",
      msg: `Flipping the width and length of the ${$(part).attr("Name")} for: ${cabNumber}. W: ${width}, L: ${length}`,
    });
  }

  function removeOverlappingHoles($, part, cabNumber, issues, flipSideOp) {
    const removedHoles = [];

    console.log("***************************************************");
    console.log("Looking for overlapping holes...");

    const holes = $(part)
      .find(`OperationHole[FlipSideOp="${flipSideOp}"]`)
      .toArray()
      .map(hole => $(hole).attr("uuid", uniqueId()));

    holes.forEach(hole => {
      const holeX = Number($(hole).attr("X"));
      const holeY = Number($(hole).attr("Y"));
      const uuid = $(hole).attr("uuid");

      if (removedHoles.includes(uuid)) return false;

      const overlappingHoles = holes.filter(h => {
        if ($(h).attr("uuid") === uuid) return false;
        const xDiff = Math.abs(holeX - Number($(h).attr("X")));
        const yDiff = Math.abs(holeY - Number($(h).attr("Y")));
        return xDiff <= 2 && yDiff <= 2;
      });

      if (overlappingHoles.length) {
        console.log("-------------");
        const allHoles = [hole, ...overlappingHoles];
        console.log(`Found ${allHoles.length} overlapping holes`);
        console.log({ holes: allHoles.map(hole => hole.attribs) });
        allHoles.sort((a, b) => Number($(b).attr("Depth")) - Number($(a).attr("Depth")));
        console.log({ allHoles });
        const holeToKeep = allHoles.shift();
        console.log({ holeToKeep });
        allHoles.forEach(hole => {
          issues.push({
            type: "removed overlapping hole",
            msg: `Removed an overlapping hole from ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(hole).attr("X")}, Y: ${$(hole).attr("Y")} with Depth: ${$(hole).attr("Depth")}`,
          });
          removedHoles.push($(hole).attr("uuid"));
          $(hole).remove();
        });
      }
    });
  }

  function removeHolesOutsidePartShape($, part, cabNumber, issues) {
    console.log("***************************************************");
    console.log("Looking for holes outside of the part shape...");
    const maxY = $(part)
      .find("ShapePoint")
      .toArray()
      .reduce((max, point) => {
        return Number($(point).attr("Y")) > max ? Number($(point).attr("Y")) : max;
      }, 0);

    const maxX = $(part)
      .find("ShapePoint")
      .toArray()
      .reduce((max, point) => {
        return Number($(point).attr("X")) > max ? Number($(point).attr("X")) : max;
      }, 0);

    console.log({ maxX, maxY });

    $(part)
      .find("OperationHole")
      .toArray()
      .forEach(hole => {
        if (Number($(hole).attr("X")) > maxX || Number($(hole).attr("Y")) > maxY) {
          console.log("Found a hole that it outside the shape. Remove it now...");
          issues.push({
            type: "remove-hole-outside-shape",
            msg: `Removing hole outside the part shape from ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(hole).attr("X")}, Y: ${$(hole).attr("Y")} with Depth: ${$(hole).attr("Depth")}`,
          });
          $(hole).remove();
        }
      });
  }

  function removeHolesNotFoundinMozaik($, part, linkedProduct, cabNumber, issues) {
    const optimizerHoles = $(part).find("OperationHole").toArray();
    const mozaikHoles = $(linkedProduct).find("OperationHole").toArray();

    const threshold = 0.01; // mm

    optimizerHoles.forEach(optimizerHole => {
      // do not remove holes that were intentionally added by the user
      // if (isManuallyAddedHole($, optimizerHole)) return;

      const { X, Y } = optimizerHole.attribs;

      const match = mozaikHoles.find(hole => {
        return Math.abs(hole.attribs.X - X) <= threshold && Math.abs(hole.attribs.Y - Y) <= threshold;
      });

      if (!match) {
        issues.push({
          type: "nonexistent-hole",
          msg: `Removing random hole added by optimizer for ${$(part).attr("Name")} (${cabNumber}) at position X: ${$(optimizerHole).attr("X")}, Y: ${$(optimizerHole).attr("Y")} with Depth: ${$(
            optimizerHole
          ).attr("Depth")}`,
        });
        $(optimizerHole).remove();
      }
    });
  }

  function getOptimizerPart($, partId) {
    return $(`OptimizePart[PartID="${partId}"]`);
  }

  function optimizePartHasOperationHoleThatIsFlipSideOp($, optimizePart) {
    return $(optimizePart).find('OperationHole[FlipSideOp="True"]').length > 0;
  }

  function getOptimizerSheets($) {
    return $("OptimizerSheet").toArray();
  }

  /**
   * https://docs.google.com/spreadsheets/d/1Bx2d79tcKN2GN0QbVRSpuGclLe5mkJUO09gMHcLl88E/edit#gid=0
   *
   * Get list of all ".opt" files from the job folder
   * Create a function to iterate each Optimizer Part and find the SourceLib and the PrintName
   * Iterate each Optimizer Part and create a JSON object of all the "criteria" attributes
   *
   * OptimizePart > Shape > ShapePoint
   * OptimizePart > Shape > Operations > OperationHole, OperationLineBore
   *
   * OptimizePart attributes: PrintName, Name, Width, Length, Quan
   * ShapePoint: X, Y
   * OperationHole: Diameter, X, Y, Depth, FlipSide
   * OperationLineBore: Quan, Diameter, X, Y, Depth, FlipSide
   *
   * Check whether part meets condition for a rule and if so modify part according to that rule
   * Keep track of which parts have been modified and which rules were applied
   * Output the modified XML and update the google drive file
   */

  return (
    <div className="mt-10">
      <JobSelect />
      <div id="operations">
        {files.length > 0 && (
          <>
            <h1 className="text-white text-xl uppercase mt-6 font-bold">OPERATIONS</h1>
            <div className="flex flex-col">
              <div className="mt-3 flex items-center">
                <input
                  className=" h-4 w-4"
                  type="checkbox"
                  checked={operations.romina}
                  onChange={() =>
                    setOperations({
                      ...operations,
                      romina: !operations.romina,
                    })
                  }
                />
                <div class="pl-1 text-sm text-gray-400">Romina Sara</div>
              </div>
              <div className="mt-3 flex items-center">
                <input
                  className=" h-4 w-4"
                  type="checkbox"
                  checked={operations.biggerHoles}
                  onChange={() =>
                    setOperations({
                      ...operations,
                      biggerHoles: !operations.biggerHoles,
                    })
                  }
                />
                <div class="pl-1 text-sm text-gray-400">7.5MM HOLES</div>
              </div>
              <OptimizerVBEdgeFix operations={operations} setOperations={setOperations} files={files} />
            </div>
          </>
        )}
      </div>
      <button
        ref={ref}
        onClick={sanitizeFiles}
        class={`mt-12 block w-full cursor-pointer items-center justify-center rounded-md bg-cyan-500 px-4 py-4 text-lg leading-6 text-white shadow-sm outline-gray-200 transition duration-150 ease-in-out hover:bg-cyan-400 focus:outline-offset-1 sm:leading-5 ${
          !files.length || !rooms.length ? "cursor-not-allowed opacity-50 hover:bg-cyan-500" : "opacity-100"
        }`}
      >
        {complete ? "Finished" : `Start`}
      </button>
      {files.length === 0 && <p className="my-1 text-sm font-semibold tracking-wide text-red-500">Unable to find any optimizer files</p>}
      <div className="mt-8 rounded-md border border-gray-400 p-8 text-sm text-white">
        <div className="py-1 text-base text-gray-400">Warnings</div>
        {report.map(issue => (
          <div className="py-2 text-sm">* {issue.msg}</div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  jobFolder: state.job.job,
  rooms: state.room.rooms,
  params: state.room.params,
});

export default connect(mapStateToProps, { setAlert })(Optimizer);
